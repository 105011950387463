import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { NavigationService } from '../../nav-shared/navigation.service';
import { HeaderService } from '../header.service';

import { AveryCustomGenericInterface } from 'navigation/nav-shared/models/interfaces';
import { AcgService } from 'navigation/nav-shared/services/acg.service';
import { NavDataService } from 'navigation/nav-shared/services/nav-data.service';
import { useBreakpoint } from 'navigation/nav-shared/utils';
import { NavDataPayload, NavLink } from '../../navigation-links.interface';

interface MobileNavLink {
  text: string;
  id: string;
  href?: string;
}

interface MobileNavScreen {
  path: string;
  title?: string;
  links: MobileNavLink[];
}

interface MobileNavPayload {
  data: MobileNavScreen[];
}

@Component({
  selector: 'nav-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  @Input() themeMode: string = 'default';

  breakpoint: string = useBreakpoint();
  // is used to detect user screen size dynamically based on resize event
  @HostListener('window:resize', [])
  onResize() {
    this.breakpoint = useBreakpoint();
  }

  activeNavMenu: number = -1;
  helpLinkUrl: string;
  isMobile: boolean;
  isTablet: boolean;
  loading: boolean = true;
  localForage: LocalForage;
  navItem: ElementRef;
  navLinks: NavLink[] = [];
  onLink: boolean = false;
  subscriptions: Subscription[] = [];
  acgs: AveryCustomGenericInterface[] = [];
  openMobileNav: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private navDataService: NavDataService,
    private acgService: AcgService
  ) {}

  ngOnInit() {
    this.navDataService
      .fetchDotCMSJSONData<NavDataPayload>('navigation-v3')
      .then((data) => {
        this.navLinks = this.navigationService.sortNavData(
          data.navData,
          'header_order',
          'theme'
        );
        this.loading = false;
      })
      .catch((err) => console.error(err));

    const fetchACGs = this.acgService.navACGs;

    fetchACGs.subscribe(
      (data) => {
        this.acgs = data;
      },
      (err) => console.error(err),
      () => fetchACGs.unsubscribe()
    );

    this.mobileNavActiveSubscription(),
      (this.subscriptions = [
        this.isMobileViewSubscription(),
        this.isTabletViewSubscription(),
        this.navMenuActiveSubscription(),
      ]);

    this.helpLinkUrl = `${environment.domain}/help/`;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  /**
   * Check if the the browser is sm or xs
   *
   * @returns {subscription}
   * @memberof HeaderNavComponent
   */
  isMobileViewSubscription(): Subscription {
    return this.navigationService.isMobileView.subscribe((value) => {
      this.isMobile = value;
    });
  }

  /**
   * Check what the mediaBreakpoint is
   *
   * @returns {subscription}
   * @memberof HeaderNavComponent
   */
  isTabletViewSubscription(): Subscription {
    return this.navigationService.isTabletView.subscribe((value) => {
      this.isTablet = value;
      if (value) {
        this.activeNavMenu = -1;
      }
    });
  }

  /**
   * Check if the Mega Menu is active
   *
   * @returns {subscription}
   * @memberof HeaderNavComponent
   */
  navMenuActiveSubscription(): Subscription {
    return this.headerService.navMenuActive
      .pipe(filter((value: boolean) => value === false))
      .subscribe(() => (this.activeNavMenu = -1));
  }

  /**
   * Open mega-menu (on hover) after a dealay and only if the mouse is still on the menu item.
   * @param {number} i
   *
   * @memberof HeaderNavComponent
   */
  onDesktopNavLink(i: number) {
    this.onLink = true;

    setTimeout(() => {
      if (this.onLink && !this.isTablet && this.activeNavMenu !== i) {
        this.activeNavMenu = i;
        this.headerService.navMenuActive.next(true);
        this.headerService.myAccountActive.next(false);
        this.headerService.shadowOverlay.next(true);
      }
    }, 300);
  }

  /**
   * cancel navigation dropdown by setting onLink variable to false.
   *
   * @memberof HeaderNavComponent
   */
  cancelNavLink() {
    if (!this.isTablet) {
      this.onLink = false;
    }
  }

  /**
   * Toggle mega-menu (on input change)
   *
   * @param {number} i
   * @param {NavLink} navLink
   * @memberof HeaderNavComponent
   */
  toggleDesktopNavLink(
    $event: { preventDefault: () => void },
    i: number,
    navLink: NavLink
  ) {
    $event.preventDefault();

    if (this.activeNavMenu !== i) {
      this.activeNavMenu = i;
      this.headerService.navMenuActive.next(true);
      this.headerService.myAccountActive.next(false);
      this.headerService.shadowOverlay.next(true);

      if (this.isTablet) {
        this.elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    } else {
      this.headerService.navMenuActive.next(false);
      if (!this.isTablet) {
        this.headerService.shadowOverlay.next(false);
      }

      if (this.isTablet) {
        this.activeNavMenu = -1;
      } else if (navLink.url.length > 0) {
        this.navigationService.navigateToRoute(navLink.url);
      }
    }
  }

  getLeftPosition(id) {
    if (id == 'templates') {
      return this.breakpoint == 'xl' ? '355px' : '310px';
    } else if (id == 'products') {
      return this.breakpoint == 'xl' ? '90px' : '0px';
    }

    return '0px';
  }

  /**
   * Open the mobile nav
   *
   * @memberof HeaderComponent
   */
  toggleMobileNav() {
    if (!this.openMobileNav) {
      this.headerService.mobileNavActive.next(true);
      this.headerService.myAccountActive.next(false);
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(true);
    } else {
      this.headerService.mobileNavActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Return subscription for Mobile Nav Activation.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  mobileNavActiveSubscription(): Subscription {
    return this.headerService.mobileNavActive.subscribe(
      (value) => (this.openMobileNav = value)
    );
  }

  generateHref(link: string) {
    return `${environment.domain}${link}`;
  }
}
