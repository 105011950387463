import { combineReducers } from '@ngrx/store';

import {
  Action,
  ADD_APPROVAL_ORDERS,
  ADD_IN_PRODUCTION_ORDERS,
  ADD_CANCELLED_ORDERS,
  ADD_PREP_SHIPPING_ORDERS,
  ADD_SHIPPED_ORDERS,
  REFRESH_ORDER_LIST,
  DISPLAY_MORE_ORDERS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_TOTAL,
  UPDATE_ORDER_SORT,
  UPDATE_ORDER_ITEM_VERSION,
  UPDATE_ORDER_ITEM_VERSIONS,
  UPDATE_ORDER_TIME,
  UPDATE_ORDER_PERIODS,
} from '../actions';

import { OrderState } from '../../app/shared/models/interfaces/order-state.interface';

const INITIAL_ORDERSTATE: OrderState = {
  orderPeriods: {
    orderTime: '30 days',
    aryProcess30Days: [],
    aryProcess6Months: [],
    aryProcess12Months: [],
    aryComplete30Days: [],
    aryComplete6Months: [],
    aryComplete12Months: [],
  },
  sortType: '',
  approvalList: [],
  inProductionList: [],
  prepShippingList: [],
  shippedList: [],
  cancelledList: [],
  orderStatus: '',
  total: 0,
  displayAmt: 12,
  refreshList: '',
};

export function OrdersDisplayReducer(state = INITIAL_ORDERSTATE, action: Action) {
  switch (action.type) {
    case ADD_APPROVAL_ORDERS:
      return Object.assign({}, state, { approvalList: action.payload.waitingApproval });
    case ADD_IN_PRODUCTION_ORDERS:
      return Object.assign({}, state, { inProductionList: action.payload.inProduction });
    case ADD_PREP_SHIPPING_ORDERS:
      return Object.assign({}, state, { prepShippingList: action.payload.prepForShipping });
    case ADD_SHIPPED_ORDERS:
      return Object.assign({}, state, { shippedList: action.payload.shipped });
    case ADD_CANCELLED_ORDERS:
      return Object.assign({}, state, { cancelledList: action.payload.canceled });
    case DISPLAY_MORE_ORDERS:
      return Object.assign({}, state, { displayAmt: action.payload });
    case UPDATE_ORDER_STATUS:
      return Object.assign({}, state, { orderStatus: action.payload });
    case UPDATE_ORDER_SORT:
      return Object.assign({}, state, { sortType: action.payload.sort });
    case UPDATE_ORDER_TOTAL:
      return Object.assign({}, state, { total: action.payload.total });
    case REFRESH_ORDER_LIST:
      return Object.assign({}, state, { refreshList: action.payload });
    case UPDATE_ORDER_PERIODS:
      const sortTimeObj = {
        aryProcess30Days: action.payload.aryProcess30Days,
        aryProcess6Months: action.payload.aryProcess6Months,
        aryProcess12Months: action.payload.aryProcess12Months,
        aryComplete30Days: action.payload.aryComplete30Days,
        aryComplete6Months: action.payload.aryComplete6Months,
        aryComplete12Months: action.payload.aryComplete12Months,
      };

      return {
        ...state,
        orderPeriods: {
          ...state.orderPeriods,
          ...sortTimeObj,
        },
      };
    case UPDATE_ORDER_TIME:
      return {
        ...state,
        orderPeriods: {
          ...state.orderPeriods,
          orderTime: action.payload.sortTime,
        },
      };

    case UPDATE_ORDER_ITEM_VERSION:
      // returns and updates the state of nested objects inside nested array.
      return {
        ...state,
        approvalList: [
          ...state.approvalList.map((order, index) => {
            if (index !== action.payload.orderIdx) {
              return order;
            }
            return {
              ...order,
              order_items: {
                ...order.order_items,
                waiting_approval: {
                  ...order.order_items.waiting_approval,
                  roll_items: [
                    ...order.order_items.waiting_approval.roll_items.map((rollItem, index) => {
                      if (index !== action.payload.rollItemIdx) {
                        return rollItem;
                      }
                      return {
                        ...rollItem,
                        details: {
                          ...rollItem.details,
                          versions: [
                            ...rollItem.details.versions.map((version, index) => {
                              if (index !== action.payload.versionIdx) {
                                return version;
                              }
                              return {
                                ...version,
                                ...action.payload.data,
                              };
                            }),
                          ],
                        },
                      };
                    }),
                  ],
                },
              },
            };
          }),
        ],
      };
    case UPDATE_ORDER_ITEM_VERSIONS:
      // returns and updates the state of nested objects inside nested array.
      return {
        ...state,
        approvalList: [
          ...state.approvalList.map((order, idx) => {
            if (idx !== action.payload.orderIdx) {
              return order;
            }
            return {
              ...order,
              order_items: {
                ...order.order_items,
                waiting_approval: {
                  ...order.order_items.waiting_approval,
                  roll_items: [
                    ...order.order_items.waiting_approval.roll_items.map((rollItem, idx) => {
                      if (idx !== action.payload.rollItemIdx) {
                        return rollItem;
                      }
                      return {
                        ...rollItem,
                        details: {
                          ...rollItem.details,
                          versions: [
                            ...rollItem.details.versions.map((version, index) => {
                              return {
                                ...version,
                                ...action.payload.data[index],
                              };
                            }),
                          ],
                        },
                      };
                    }),
                  ],
                },
              },
            };
          }),
        ],
      };
    default:
      return state;
  }
}

export const ordersReducer = combineReducers({
  filterOrder: OrdersDisplayReducer,
});
