// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  environment: 'develop',
  production: false,
  accountService: 'https://dev-cms.avery.com/account',
  appDomain: 'https://dev-cms.avery.com',
  navDomain: 'https://dev-cms.avery.com/avylib/dist/assets',
  authKey: 'wwjH7clZ8E5v5BsgCqQ5zHYf9OlZ8dCB',
  bazaarVoice:
    'https://apps.bazaarvoice.com/deployments/averyproducts-1/main_site/staging/en_US/bv.js',
  braintree: 'https://dev-cms.avery.com/braintree',
  consumerPrivacyUrl: 'https://dev-cms.avery.com/ccpa',
  demandBase:
    'https://api.demandbase.com/api/v2/ip.json?key=bd38bd2672863d51e8e5dda8ca51dba2b99f302a',
  domain: 'https://dev-cms.avery.com',
  dpo3: 'https://dpotest.print.avery.com/dpp/public/v3',
  dpo4: 'https://dpotest.print.avery.com/dpp/public/v4',
  dpoDeploymentID: 'US_en',
  dpoDomain: 'https://dpotest.print.avery.com',
  dpoDownloadables: 'https://dpotest.print.avery.com/dpp/public/v3/content/downloadables',
  elasticSearch: 'https://search-dev.avery.com',
  image: 'https://img.avery.com',
  labelsEmail: 'https://ixntcv9013.execute-api.us-east-1.amazonaws.com/dev/email',
  magento: 'https://tst-cart.avery.com',
  sampleService: 'https://dev-cms.avery.com/sample',
  ssoService: 'https://dev-cms.avery.com/authenticate',
  W2GI: 'https://hosted.where2getit.com/avery/productlocator.html?sku=',
};

export const config = {
  authKey: 'wwjH7clZ8E5v5BsgCqQ5zHYf9OlZ8dCB',
  env: 'dev',
  recaptchaKey: '6Ld76NsUAAAAAG0c0SDAT3M11Qgp5siUA39_Mr1e',
  recaptchaSecret: '6Ld76NsUAAAAALFx963ytrUOC1ssOXn6OgqmnCrj',
  theme: {
    cookieKey: 'avythmmde',
    modes: {
      default: {
        landingPage: environment.domain,
        toggleLabel: 'Avery',
      },
      industrial: {
        landingPage: `${environment.domain}/industrial`,
        toggleLabel: 'Avery Industrial',
        URLregex: /^\/industrial/,
      },
    },
  },
  version: require('../../package.json').version,
};
